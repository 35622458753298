.validationError {
  font-size: 0.8rem;
  text-align: left;
  color: red;
  padding-left: 0.5rem;
  display: block;
}

label{
  font-size: .875rem;
  line-height: 1.5;
  display: block;
  margin-bottom: unset;
  float: left;
  padding-left: 0.5rem;
}