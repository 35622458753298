.Footer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  background: rgba(0,0,0,0.8);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);

  p {
    font-size: 0.9rem;
    color: silver;
    padding: 0.5rem 0;
    margin: 0;
    white-space: pre-wrap;
  }

  a {
    font-size: 0.9rem;
    color: #62B6CB;;
    padding: 0.5rem 0;
    margin: 0;
  }
}