.Divider {
  margin: 3rem auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 50px;
  }
  span{
    height: 50px;
    width: 1px;
    background-color: silver;
  }
}

@media screen and (max-width: 991px) {
  .Divider {
    margin: 1.5rem auto;
  
    span {
      height: 30px;
    }
  }
}