.JoinUs {
  height: 100%;
  .nav-tabs{
    border-color: transparent;
    .nav-item {
      width: 50%;

      .nav-link {
        height: 100%;
        color: rgba(0, 0, 0, 0.8);
        font-size: 1.75rem;
        border-color: rgba(0, 0, 0, 0.1);

        &:hover,
        &:focus {
          border-color: rgba(0, 0, 0, 0.4);
        }
        &.active {
          background: rgba(0, 0, 0, 0.8);
          -webkit-backdrop-filter: saturate(180%) blur(20px);
          backdrop-filter: saturate(180%) blur(20px);
          color: #62B6CB;
          border: none;
        }
      }
    } 
  }

  .tab-pane {
    .banner {
      height: inherit;
    }
    .details {
      display: flex;
      flex-direction: column;
      align-self: center;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      h3 {
        font-weight: bold;
        padding:  2rem 3rem 0 3rem;
        text-align: left;
      }

      ul{
        width: 100%;
        padding:  2rem 2rem 0 2rem;    
        list-style: none;
        li {
          text-align: left;
          &::before{
            width: 2rem;
            display: inline-block;
            text-align: left;
            content: '\25A0';
            color: #62B6CB;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .JoinUs {
    .banner {
      height: 300px;
    }
    .tab-pane .details{
      h3 {
        margin: 0;
      }
      ul {
        margin: 0;
        li {
          font-size: 0.9rem;
        }
      }
    }
    .nav-tabs .nav-item .nav-link {
      font-size: 1.25rem;
    }
  }
}