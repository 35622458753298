.modal-apply-btn {
  // margin: 1rem 0 0 1rem;
  background: rgba(0,0,0,0.8);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  padding: 0.5rem 1rem;
  border-radius: unset;
  border: unset;
  letter-spacing: 0.1rem;
  font-size: 1.2rem;
  color: silver;
  float: left;
  &:enabled:hover {
    color: #62B6CB;
    text-decoration: none;
  }
  &:disabled {
    background-color: #808080;
    cursor: not-allowed;
  }
}
