.LeaseOrRent {
  // height: 100vh;
  // padding: 10% 0;
  table {
    border-collapse: collapse;
    width: 100%;
    height: 100%;

    td,
    th {
      border: 1px solid #ddd;
      padding: 8px;
    }

    tr {
      &:nth-child(even) {
        background-color: #f2f2f2;
      }
      &:hover {
        background-color: #ddd;
      }
    }

    th {
      padding-top: 12px;
      padding-bottom: 12px;
      // text-align: left;
      background: rgba(0,0,0,0.8);
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
      color: #62B6CB;
    }
  }

  .description {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    h1, h3 {
      padding-left: .5rem;
      width: 100%;
      text-align: start;
      font-weight: bold;
    }

    h1{
      font-weight: bold;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.9rem;
      text-align: justify;
    }
  }
}

@media screen and (max-width: 991px) {
  .LeaseOrRent {
    flex-direction: column-reverse;
    td,
    th {
      word-break: break-all;
      font-size: 0.75rem;
    }
  }
}