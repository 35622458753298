.Navigation {
  position: fixed;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  height: 100%;
  max-height: 75px;
  transition: 0.5s ease-out;
  justify-content: center;
  z-index: 10;
  border-bottom: 1px solid silver;

  &.scrolled {
    max-height: 50px;
    .navbar-brand img {
      max-height: 60px;
    }
  }

  .navbar-brand {
    display: flex;
    align-self: flex-start;
    justify-content: center;
    margin: 0;
    width: 60%;
    a{
      background-color: #333;
      padding: 1.5rem;
      
    }
    img {
      height: 90px;
    }
  }

  .navbar-nav {
    max-width: 1000px;
    width: 100%;
    height: 50px;
    justify-content: space-around;
    align-items: center;

  }
  
  .nav-link {
    height: inherit;
    img{
      max-height: 100px;
      height: 100%;
    }
  }

  .navbar-collapse {
    flex: initial;
    min-width: 750px;
    width: 100%;
    justify-content: center;
  }

  a {
    color: black;
    &:hover {
      color: #62B6CB;
    }

    &.dropdown-item {
      color: black;
    }
  }
}

.join-us {
  a {
    position: fixed;
    bottom: 10vh;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    padding: 0.5rem 4rem 0.5rem 1rem;
    transition: all 0.5s ease-out;
    z-index: 10;
    letter-spacing: 0.1rem;
    font-size: 1.5rem;
    color: silver;
    &:hover {
      color: #62B6CB;
      text-decoration: none;
    }
  }
  &.decrease a {
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
  }
}


@media screen and (max-width: 991px) {
  .Navigation{
    padding: 0;
    max-height: 50px;
    justify-content: space-between;
    flex-direction: row;

    &.scrolled {
      max-height: 50px;
      .navbar-brand img {
        max-height: 40px;
      }
    }

    .navbar-brand {
      z-index: 1;
      // display: inline;
      margin-left: 2rem;
      width: unset;
      padding-bottom: 0;
      margin-bottom: -11px;
      a {
        padding: .5rem 1rem;
      }
      img {
        max-height: 40px;
      }
    }
    .navbar-toggler {
      margin-right: 1rem;
    }

    .navbar-collapse{
      background: rgba(255, 255, 255, 0.95);
      // background: rgba(0, 0, 0, 0.8);
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
      min-width: unset;
      padding: 1rem;
      border-bottom: 1px solid silver;
      .navbar-nav {
        height: unset;
        max-width: unset;
        .nav-item.logo {
          display: none;
        }
      }  
    }
  }
  
  .join-us a {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
  }
}