.PrivacyPolicy {
    text-align: justify;
    width: 60vw;
    margin: 2rem auto;

    .back {
        position: fixed;
        bottom: 10vh;
        right: 0;
        background: rgba(0, 0, 0, 0.8);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        transition: all 0.5s ease-out;
        z-index: 10;
        letter-spacing: 0.1rem;
        color: silver;
        &:hover {
          color: #62B6CB;
          text-decoration: none;
        }
    }
}